import { Injectable, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authTokenKey = 'authToken';
  private refreshTokenKey = 'refreshToken';

  constructor(@Inject(PLATFORM_ID) 
    private platformId: Object,
    private http: HttpClient
  ) {}

  public login(body: any): Observable<any> {
    return this.http.post<any>(environment.ADMIN_LOGIN, body);
  }

  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return !!localStorage.getItem('authToken'); // Devuelve true si hay un token
    }
    return false;
  }

  public logout(): void {
    window.localStorage.clear();
  }

  // Guarda los tokens en el almacenamiento local
  setTokens(authToken: string, refreshToken: string): void {
    localStorage.setItem(this.authTokenKey, authToken);
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  // Obtiene el authToken desde el almacenamiento local
  getAuthToken(): string | null {
    return localStorage.getItem(this.authTokenKey);
  }

  // Obtiene el refreshToken desde el almacenamiento local
  getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  // Refresca el authToken utilizando el refreshToken
  refreshAuthToken(): Observable<string | null> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) return of(null);

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post<{ authToken: string }>(`${environment.ADMIN_REFRESH}`, { refreshToken }, { headers })
      .pipe(
        map((response) => {
          if (response && response.authToken) {
            this.setTokens(response.authToken, refreshToken);
            return response.authToken;
          }
          return null;
        }),
        catchError(() => of(null))
      );
  }

  // Limpia los tokens (por ejemplo, en caso de cierre de sesión)
  clearTokens(): void {
    localStorage.removeItem(this.authTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
  }
}