const API_URL = 'https://dashboard-stats.xyxen-solutions.com/dev/api/';
export const environment = {
    production: false,
    API_URL: API_URL,
    ADMIN_LOGIN: `${API_URL}auth/login`,
    GET_ADMINS: `${API_URL}admins/`,
    REGISTER_ADMIN: `${API_URL}admins/register`,
    ADMIN_OPTIONS: `${API_URL}admins/:id`,
    ADMIN_REFRESH: `${API_URL}auth/refresh`,
    SPORT_OPTIONS: `${API_URL}sports/`,
    LEAGUE_OPTIONS: `${API_URL}leagues/`,
    TEAM_OPTIONS: `${API_URL}teams/`,
    PLAYER_OPTIONS: `${API_URL}players/`,
    SEASON_OPTIONS: `${API_URL}seasons/`,
    MATCH_OPTIONS: `${API_URL}matches/`
  };

export const COUNTRIES_KEY = '1613|c8zqtCkLN2qImOFz8yWxK2CG2b7vv6cPMRUirneG';
export const COUNTRIES = 'https://restfulcountries.com/api/v1/countries?fetch_type=slim';
export const FILES = 'https://dashboard-stats.xyxen-solutions.com/dev';